import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authToken: any;
  public user: any;
  private baseUrl = environment.baseUrl;
  private jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem('loggedAdmin')) || {};
  }

  /** Authenticate user */
  public login(user: { email: string, password: string }) {
    return this.http.post(`${this.baseUrl}auth/admin-login`, user);
  }

  /** Store administrator data in local storage */
  public storeAdminData(token, user) {
    localStorage.setItem('auth_token', token);
    localStorage.setItem('loggedAdmin', JSON.stringify(user));

    this.authToken = token;
    this.user = user;
  }

  /** Validate JWT token expiration */
  public loggedIn() {
    return !this.jwtHelper.isTokenExpired(this.getAdminToken());
  }

  /** Reset password via email */
  public resetPassword(email: string) {
    return this.http.post(`${this.baseUrl}auth/reset-password`, { email }).toPromise();
  }

  /** Logout administrator */
  public logout() {
    this.authToken = null;
    this.user = {};
    localStorage.clear();
  }

  /** Get administrator data */
  public getAdminData() {
    return this.user;
  }

  /** Get administrator token */
  public getAdminToken() {
    return localStorage.getItem('auth_token');
  }

  /** Set Admin user */
  public setAdminUser(user) {
    this.user = user;
  }
}
